import { render, staticRenderFns } from "./comicPublish.vue?vue&type=template&id=1c6cddcd"
import script from "./comicPublish.vue?vue&type=script&lang=js"
export * from "./comicPublish.vue?vue&type=script&lang=js"
import style0 from "./comicPublish.vue?vue&type=style&index=0&id=1c6cddcd&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports