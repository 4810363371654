<!--
 * @Author: daipeng
 * @Date: 2019-09-06 11:50:03
 * @LastEditors: VSCode
 * @LastEditTime: 2019-09-09 11:01:07
 * @Description:
 -->
<template>
    <div class="my-comic page-container flex-column">
        <!-- search-box -->
        <el-form class="search-box" ref="searchForm" align="center" :model="searchData" inline @keyup.enter.native.prevent="searchHandle">
            <el-form-item prop="query_text" label="关键词:">
                <el-input v-model="searchData.query_text" placeholder="输入动态漫名称或作者名" clearable class="search-input" @keyup.enter.native.prevent="searchHandle"></el-input>
            </el-form-item>
            <el-form-item prop="show_status" label="显示状态:">
                <el-select v-model="searchData.show_status" style="width:100px" placeholder="选择状态">
                    <el-option v-for="(item,index) in showTypes" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" @click="searchHandle">查询</el-button>
            </el-form-item>
            <el-form-item>
                <el-button size="mini" @click="clearSearchHandle">重置</el-button>
            </el-form-item>
        </el-form>
        <!-- search-box-end -->
        <div class="my-comic-operation">
            <div class="flex-row-between">
                <div class="flex-row-center">
                    <el-button type="primary" size="mini" @click="addComicHandle">新增动态漫</el-button>
                    <el-button size="mini" @click="displayComicHandle(1)">上架</el-button>
                    <el-button size="mini" @click="displayComicHandle(0)">下架</el-button>
                    <el-tabs class="ml20 f14" v-model="currentTabId" @tab-click="tabChangeHandler">
                        <template v-for="tab in auditType">
                            <el-tab-pane :key="tab.id" :name="tab.id + ''">
                                <div slot="label">{{tab.name}}<span class="tab-icon">{{tab.total}}</span></div>
                            </el-tab-pane>
                        </template>
                    </el-tabs>
                </div>
            </div>
        </div>
        <div class="my-comic-selectbox">
            <i class="el-icon-info color-green mr10"></i><span class="mr10">共 {{pagination.total}} 项</span><span class="mr10">已选择 <span class="color-green">{{selectComicList.length}}</span> 项</span>
            <el-button type="text" :disabled="selectComicList.length === tableList.length" @click="selectAllHandle">全选</el-button>
            <el-button type="text" :disabled="!selectComicList.length" @click="removeSelectHandle">清空</el-button>
        </div>
        <div v-show="!isTable" class="my-comic-table" ref="cardTable" v-loading="isloading" element-loading-text="加载中..." element-loading-spinner="el-icon-loading" v-empty="noData">
            <template v-if="tableList.length">
                <anComicCard ref="card" v-for="(card, index) in tableList" :key="card.animation_id" :index="index" :showSelect="true" :list.sync="tableList" :selectComicList.sync="selectComicList" :comicData="card"></anComicCard>
            </template>
        </div>
        <paginationPlus :currentPage.sync="pagination.pageIndex" :pageSizes="[8, 20, 30, 40]" :pageSize.sync="pagination.pageSize" :total="pagination.total" @callback="pageChangeHandle"></paginationPlus>
        <comic-publish
            @refreshData="refreshData"
            v-if="currentPublishComicData"
            :comicData.sync="currentPublishComicData"
            :platformList="platformList"
        ></comic-publish>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-12 20:00:22
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-12 17:33:59
 * @Description: 管理动态漫-我的动态漫
 * @Company: 成都二次元动漫
 */
import { searchMixin, anComicMixin, paginationMixin } from '@/mixins';
import auditComicDialog from '@/views/main/adminCenter/auditComic/auditComicDialog';
import comicPublish from '@/components/comic/comicPublish';
const query_type = ['all', 'auditing', 'refuse', 'pass'];

export default {
    mixins: [searchMixin, anComicMixin, paginationMixin],
    components: { auditComicDialog, comicPublish },
    data() {
        return {
            platformList: null, // 平台列表
            isloading: false,
            noData: true,
            pagination: {
                page_size: 8
            },
            searchData: {
                query_type: query_type[0],
                query_text: null,
                show_status: -2
            },
            auditType: [
                { id: 'all', name: '全部', total: null },
                { id: 'init', name: '待提交', total: null },
                { id: 'auditing', name: '审核中', total: null },
                { id: 'refuse', name: '未通过', total: null },
                { id: 'pass', name: '已通过', total: null }
            ],
            showTypes: [
                { id: -2, name: '全部' },
                { id: -1, name: '未上线' },
                { id: 0, name: '已下架' },
                { id: 1, name: '已上架' }
            ],
            tableList: [],
            isTable: false,
            currentPublishComicData: null, // 当前点击发布的章节信息
            selectComicList: [] // 选择的动态漫
        };
    },
    computed: {
        currentTabId: {
            get() {
                return this.searchData.query_type + '';
            },
            set() {}
        }
    },
    created() {
        this.getComicPlatformList();
        this.getPublicClass().then(res => (this.publicClass = res));
        this.getTableList();
    },
    methods: {
        // 获取平台列表
        getComicPlatformList() {
            return this.$api('getPublicConfig').then(({ data }) => {
                this.platformList = data.platform_list.map((platform) => {
                    return {
                        platformId: platform.id,
                        name: platform.name,
                        edit: false,
                        selectTypeId: null,
                        publishTime: null
                    };
                });
            });
        },
        // 刷新界面数据，用于改变发布信息后刷新
        refreshData(comicData) {
            this.getTableList().then(() => {
                const newComic = this.tableList.find(item => item.animation_id === comicData.animation_id);
                this.currentPublishComicData = newComic;
            });
        },
        // tab切换
        tabChangeHandler({ name }) {
            this.searchData.query_type = name;
            this.$nextTick(this.searchHandle);
        },
        // 选择所有动态漫
        selectAllHandle() {
            this.selectComicList = this.tableList;
        },
        // 清空所选动态漫
        removeSelectHandle() {
            this.selectComicList = [];
        },
        // 动态漫上/下架
        displayComicHandle(show_status) {
            if (!this.selectComicList.length) return this.$message.info('请先选择动态漫');
            const msg = show_status === 0 ? '下架' : '上架';
            this.$utils.confirm('', `确定${msg}选中动态漫吗？`, `${msg}中...`, (action, instance, done) => {
                return this.$api('displayAnComic', { show_status, animation_ids: this.selectComicList.map(comic => comic.animation_id) }).then(res => {
                    this.selectComicList = [];
                    this.getTableList();
                });
            });
        },
        // 展示发布dialog
        showDialog(comicData) {
            if (this.platformList === null || this.platformList.length === 0) {
                this.$message('平台列表尚未加载完成，请稍后点击');
                return;
            }
            this.currentPublishComicData = this.$utils.cloneDeep(comicData);
        },
        // 查看动态漫详情
        previewComicInfo(comicData) {
            this.changeDialog(true, '', 0, { ...comicData, ...this.$utils.formatPublicClass(this.publicClass[0].child, comicData.classIdlist.split(',')) });
        },
        // 新增动态漫
        addComicHandle() {
            this.$router.push({ name: 'anComicInfo', query: { editType: 'add' } });
        },
        // 搜索
        searchHandle() {
            this.getTableList();
        },
        // 清除搜索
        clearSearchHandle() {
            this.resetForm('searchForm');
            this.searchData.query_type = 'all';
            this.searchHandle();
        },
        // 查询列表
        getTableList() {
            this.isloading = true;
            let pagination = {};
            for (let [key, value] of Object.entries(this.pagination)) {
                if (key === 'total') {
                    continue;
                }
                pagination[this.$utils.snakeCase(key)] = value;
            }

            return this.$api('getAnComicList', { ...this.searchData, ...pagination }).then(res => {
                this.$refs.cardTable.scrollTop && (this.$refs.cardTable.scrollTop = 0);
                const { rows, page_index, page_size, total, status_data } = res.data;
                this.noData = (rows.length !== 0);
                this.tableList = rows;
                this.pagination = {
                    ...this.pagination,
                    pageIndex: page_index,
                    pageSize: page_size,
                    total
                };
                this.auditType = this.auditType.map(type => {
                    if (type.id === null) {
                        type.total = Object.values(status_data).reduce((s, n) => {
                            s += n;
                            return s;
                        }, 0);
                    } else type.total = status_data[type.id];
                    return type;
                });
                this.isloading = false;
            });
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        }
    }
};
</script>

<style lang="scss">
    .my-comic{
        .search-box{
            flex-basis: 50px;
        }
        &.page-container{
            padding: 20px 0 0;
        }
        .search-input{
            width: 200px;
        }
        &-operation{
            padding: 0 10px;
            flex-basis: 54px;
            .tab-icon{
                margin-left: 5px;
            }
        }
        &-table{
            display: flex;
            flex-grow: 1;
            width: 100%;
            flex-wrap: wrap;
            justify-content: flex-start;
            box-sizing: border-box;
            padding: 10px 0  10px 20px;
            position: relative;
            overflow-y: auto;
            &-list{
                flex-grow: 1;
                margin: 10px 20px;
                .el-table__body-wrapper{
                    height: calc(100% - 40px);
                    position: relative;
                    overflow: auto;
                }
            }
        }
        &-publish{
            padding: 20px;
            .el-checkbox-group{
                flex-flow: row wrap;
                .el-checkbox{
                    min-width: 100px;
                }
            }
            .el-checkbox{
                margin: 0 30px 10px 0;
            }
        }
        &-selectbox{
            flex-basis: 34px;
            padding: 0 10px;
            margin: 0 20px 10px;
            border-radius: 4px;
            background-color: rgba(230, 247, 255, 1);
            border: 1px solid rgba(186, 231, 255, 1);
        }
    }
    .el-message-box__title{
        span{
            font-size: 16px;
        }
    }
    label.platform-checkbox{
        margin-left: 0;
        margin-right: 30px;
    }
</style>
